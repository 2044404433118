import Vue from 'vue'
import VueRouter, {
  RouteConfig,
} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    component: () => import(/* webpackChunkName: "signIn" */ `@/views/sign-in/index.vue`),
    name: `SignIn`,
    path: `/sign-in`,
  },
  {
    component: () => import(/* webpackChunkName: "signUp" */ `@/views/sign-up/index.vue`),
    name: `SignUp`,
    path: `/sign-up`,
  },
  {
    component: () => import(/* webpackChunkName: "dashboard" */ `@/views/dashboard/index.vue`),
    name: `Dashboard`,
    path: `/`,
  },
  {
    component: () => import(/* webpackChunkName: "places" */ `@/views/places/index.vue`),
    name: `Places`,
    path: `/places`,
  },
  {
    component: () => import(/* webpackChunkName: "placesCreate" */ `@/views/places/create/index.vue`),
    name: `PlacesCreate`,
    path: `/places/create`,
  },
  {
    component: () => import(/* webpackChunkName: "placesEdit" */ `@/views/places/_id/edit/index.vue`),
    name: `PlacesEdit`,
    path: `/places/:id/edit`,
  },
  {
    component: () => import(/* webpackChunkName: "words" */ `@/views/words/index.vue`),
    name: `Words`,
    path: `/words`,
  },
  {
    component: () => import(/* webpackChunkName: "chapters" */ `@/views/chapters/index.vue`),
    name: `Chapters`,
    path: `/chapters`,
  },
  {
    component: () => import(/* webpackChunkName: "chaptersCreate" */ `@/views/chapters/create/index.vue`),
    name: `ChaptersCreate`,
    path: `/chapters/create`,
  },
  {
    component: () => import(/* webpackChunkName: "chaptersEdit" */ `@/views/chapters/_id/edit/index.vue`),
    name: `ChaptersEdit`,
    path: `/chapters/:id/edit`,
  },
  {
    component: () => import(/* webpackChunkName: "lessons" */ `@/views/lessons/index.vue`),
    name: `Lessons`,
    path: `/lessons`,
  },
  {
    component: () => import(/* webpackChunkName: "lessonsCreate" */ `@/views/lessons/create/index.vue`),
    name: `LessonsCreate`,
    path: `/lessons/create`,
  },
  {
    component: () => import(/* webpackChunkName: "lessonsEdit" */ `@/views/lessons/_id/edit/index.vue`),
    name: `LessonsEdit`,
    path: `/lessons/:id/edit`,
  },
  {
    component: () => import(/* webpackChunkName: "grammars" */ `@/views/grammars/index.vue`),
    name: `Grammars`,
    path: `/grammars`,
  },
  {
    component: () => import(/* webpackChunkName: "grammarsCreate" */ `@/views/grammars/create/index.vue`),
    name: `GrammarsCreate`,
    path: `/grammars/create`,
  },
  {
    component: () => import(/* webpackChunkName: "grammarsEdit" */ `@/views/grammars/_id/edit/index.vue`),
    name: `GrammarsEdit`,
    path: `/grammars/:id/edit`,
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: `history`,
  routes,
})

export default router
