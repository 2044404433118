































































































import mixins from 'vue-typed-mixins'
import {
  vModel,
} from '@/mixins'
import AppTitle from '@/components/Core/AppTitle.vue'
import {
  authStore,
} from '@/store'
import firebase from '@/plugins/firebase'

export default mixins(vModel)
  .extend({
    components: {
      AppTitle,
    },
    data: () => ({
      loadingSignOut: false,
      appItems: [
        {
          title: `Dashboard`,
          icon: `mdi-view-dashboard`,
          path: `/`,
          exact: true,
        },
        {
          title: `Places`,
          icon: `mdi-map-marker`,
          path: `/places`,
        },
        {
          title: `Chapters`,
          icon: `mdi-book-open-variant`,
          path: `/chapters`,
        },
        {
          title: `Lessons`,
          icon: `mdi-star-circle`,
          path: `/lessons`,
        },
        {
          title: `Grammars`,
          icon: `mdi-format-list-bulleted-triangle`,
          path: `/grammars`,
        },
        {
          title: `Words`,
          icon: `mdi-format-color-text`,
          path: `/words`,
        },
      ],
      userItems: [
        {
          title: `Settings`,
          icon: `mdi-cog`,
          path: `/settings`,
        },
      ],
    }),
    computed: {
      user (): firebase.User | null {
        return authStore.user
      },
    },
    methods: {
      async signOut () {
        try {
          this.loadingSignOut = true
          await authStore.signOut()
        } catch (err) {
          console.error(err)
        }
        this.loadingSignOut = false
      },
    },
  })
